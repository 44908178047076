import { type Params } from "@remix-run/react";
import { css } from "ui/css";

export interface IMatches {
  id: string;
  pathname: string;
  params: Params<string>;
  data: unknown;
  handle: {
    breadcrumb?: (loaderData: any) => React.ReactNode;
    campaignType?: "ppc" | "ppl";
    campaignSwitcherAddon?: (loaderData: any) => React.ReactNode;
  };
}

type HandleType = {
  breadcrumb: (match?: IMatches) => React.ReactNode;
};
export const Breadcrumbs = ({ matches }: { matches: IMatches[] }) => {
  const breadcrumbMatches = matches.filter(
    (match) =>
      match.handle &&
      typeof match.handle === "object" &&
      Object.prototype.hasOwnProperty.call(match.handle, "breadcrumb"),
  );

  if (breadcrumbMatches.length === 0) return <></>;

  const match = breadcrumbMatches.at(-1);

  return (
    <div className={css({ marginBlockStart: "24px" })}>
      {(match?.handle as HandleType).breadcrumb(match)}
    </div>
  );
};
